var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('t-dropdown',{attrs:{"variant":"smActions"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
        var mousedownHandler = ref.mousedownHandler;
        var focusHandler = ref.focusHandler;
        var blurHandler = ref.blurHandler;
        var keydownHandler = ref.keydownHandler;
return [_c('button',{class:_vm.getButtonClass,attrs:{"aria-label":"Voucher Actions","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[(_vm.lockReq)?_c('loading',{attrs:{"height":25,"opacity":0,"color":"#fff","active":true,"loader":"bars","is-full-page":false}}):_c('span',{class:{ 'pl-3': _vm.status === 'Expired' }},[(_vm.status !== 'Expired')?_c('i',{staticClass:"ml-2 mr-2 fas fa-chevron-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.status)+" ")])],1)]}},{key:"default",fn:function(ref){
        var hide = ref.hide;
return [(_vm.status !== 'Expired')?_c('div',{staticClass:"py-1 mt-px bg-white rounded-md shadow-lg",on:{"click":function($event){return hide()}}},[(_vm.status !== 'Active')?_c('t-dropdown-item',{staticClass:"font-medium text-14px",on:{"click":function($event){return _vm.handleVehicleLockUnlock(true)}}},[_vm._v(" "+_vm._s(_vm.$t('components.voucherManagement.actions.activate'))+" ")]):_vm._e(),(_vm.status !== 'Inactive')?_c('t-dropdown-item',{staticClass:"font-medium text-14px",on:{"click":function($event){return _vm.handleVehicleLockUnlock(false)}}},[_vm._v(" "+_vm._s(_vm.$t('components.voucherManagement.actions.inactivate'))+" ")]):_vm._e()],1):_vm._e()]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }