<template>
  <div class="w-1/2">
    <div class="voucher-card p-4 flex items-center justify-center">
      <div class="vouchar-card-body flex items-center justify-between w-full">
        <div class="">
          <h3 class="text-lg">{{ showVoucherCredit() }}</h3>
          <p class="text-xs font-bold">
            {{
              $t('components.voucherManagement.modals.notifyRider.card.reward')
            }}
          </p>
        </div>
        <div class="text-right">
          <p class="font-bold my-1 text-xs">
            {{
              $t(
                'components.voucherManagement.modals.notifyRider.card.voucherId'
              )
            }}
          </p>
          <p class="text-blue-500 text-xs">#{{ code }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VoucherBlock',
  components: {},
  data() {
    return {}
  },
  props: {
    voucher_type: {
      required: false,
    },
    reward_amount: {
      required: false,
    },
    code: {
      required: false,
    },
  },
  computed: {
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
  },
  mounted() {
    console.log('data')
  },
  methods: {
    showVoucherCredit() {
      let prefix = this.voucher_type === 'RV_CREDIT' ? this.currencySymbol : ''
      let postfix = this.voucher_type === 'RV_CREDIT' ? '' : 'Minutes'
      let amount =
        this.voucher_type === 'RV_CREDIT'
          ? this.reward_amount
          : parseInt(this.reward_amount)
      return `${prefix}${amount} ${postfix}`
    },
  },
}
</script>

<style lang="scss" scoped>
.voucher-card {
  width: 100%;
  height: 100%;
  background: #e0f8e6;
  border-radius: 22px;
  position: relative;
  .promo-right-icon {
    width: 180px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .vouchar-card-body {
    h3 {
      font-family: Roboto;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0d0d0d;
    }
    span {
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
}
</style>
