<template>
  <content-section>
    <div class=" flex flex-col md:flex-row mt-4 gap-20">
      <div class="w-full md:w-2/5">
        <VoucherDetailsCard
          ref="voucherDetailsCard"
          :type="getVoucherType"
          :reward="getVoucherReward"
          :expire-at="getExpiresAtInFormat"
          :expire-date="getExpireDate"
          :title="getLifespan"
          :location="getLocation"
        />
      </div>
      <div class="w-full md:w-3/5">
        <table :id="`riderTable`" class="fst">
          <tr class="fst-row">
            <th class="fst-head-row-item">
              {{
                $t(
                  'components.voucherManagement.details.voucherDetails.voucherType'
                )
              }}
            </th>
            <td class="fst-row-item">
              {{
                form && form.voucher_type === 'RV_CREDIT'
                  ? $t(
                      'components.voucherManagement.details.voucherDetails.creditDuration'
                    )
                  : $t(
                      'components.voucherManagement.details.voucherDetails.tripDuration'
                    )
              }}
            </td>
          </tr>
          <tr class="fst-row">
            <th class="fst-head-row-item">
              {{
                $t('components.voucherManagement.details.voucherDetails.title')
              }}
            </th>
            <td class="fst-row-item">{{ form && form.title }}</td>
          </tr>
          <tr class="fst-row">
            <th class="fst-head-row-item">
              {{
                $t(
                  'components.voucherManagement.details.voucherDetails.expireDate'
                )
              }}
            </th>
            <td class="fst-row-item">
              {{ form.expires_at | friendlyDateTime }}
            </td>
          </tr>
          <tr class="fst-row">
            <th class="fst-head-row-item">
              {{
                $t(
                  'components.voucherManagement.details.voucherDetails.lifeSpan'
                )
              }}
            </th>
            <td class="fst-row-item">{{ form && form.lifespan }} hrs</td>
          </tr>
          <tr class="fst-row">
            <th class="fst-head-row-item">
              {{
                $t(
                  'components.voucherManagement.details.voucherDetails.assignedFleets'
                )
              }}
            </th>
            <td class="fst-row-item">{{ getLocation }}</td>
          </tr>
          <tr class="fst-row">
            <th class="fst-head-row-item">
              {{
                $t(
                  'components.voucherManagement.details.voucherDetails.description'
                )
              }}
            </th>
            <td class="fst-row-item">{{ form && form.description }}</td>
          </tr>
        </table>
      </div>
    </div>
  </content-section>
</template>

<script>
import ContentSection from '@/components/layout/ContentSection'
import VoucherDetailsCard from './VoucherDetailsCard.vue'
export default {
  name: 'VoucherDetailsPageHeader',
  props: {
    voucher: {
      type: Object,
      default: () => null,
    },
    currencySymbol: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      form: null,
      dateFormat: {
        format: 'DD MMM YY',
      },
    }
  },
  components: {
    ContentSection,
    VoucherDetailsCard,
  },
  watch: {
    voucher: {
      immediate: true,
      deep: true,
      handler(data) {
        if (Object.keys(data).length > 1) {
          this.form = { ...data }
        }
      },
    },
  },
  computed: {
    getVoucherType() {
      return this.form?.voucher_type
    },
    getLifespan() {
      let lifespan = this.form?.lifespan
      if (!lifespan) return ''
      return `${lifespan} HRS`
    },
    getLocation() {
      let fleets = this.form?.assigned_fleets
      let locations = []
      if (fleets && Array.isArray(fleets)) {
        locations = fleets.map((fleet) => fleet.name)
      }
      return locations.join(', ')
    },
    getExpiresAtInFormat() {
      let expires_at = this.form?.expires_at
      if (expires_at && this.$dayjs().isValid(expires_at)) {
        return this.$UTCAwareTime(expires_at, this.dateFormat)
      }
      return ''
    },
    getExpireDate() {
      return this.form?.expires_at
    },
    getVoucherReward() {
      const amount = this.form?.reward_amount || 0

      return this.getVoucherType === 'RV_CREDIT'
        ? `${this.currencySymbol}${amount}`
        : this.getVoucherType === 'RV_TRIP'
        ? `${amount} min`
        : ''
    },
  },
}
</script>

<style lang="scss" scoped>
.fst-container {
  @apply overflow-x-auto;

  & .fst-wrapper {
    min-height: 30vh;
    height: 100%;

    @apply relative inline-block min-w-full overflow-hidden;
  }
}

.fst {
  @apply min-w-full leading-normal;

  & .fst-head {
    @apply shadow-md;
  } // .fst-head

  .fst-head-row {
    @apply shadow-md border-b border-gray-200;
    //
  }
  .fst-head-row-item {
    @apply pt-2 pb-2 font-bold tracking-wider text-left text-14px text-oDark outline-none focus:outline-none;

    &:first-child,
    &:last-child {
      @apply px-3;
    }
  }
  & .fst-row {
    @apply border-b border-gray-100;

    &:nth-child(odd) {
      background-color: #f7fafc;
      // background-color: #fafdff;
    }

    // row data
    & .fst-row-item {
      color: #6d6d75;

      // @apply  pr-3 font-medium text-14px transform;
      @apply py-2 font-medium text-14px;

      &:first-child,
      &:last-child {
        @apply px-3;
      }
    }

    // row-data hover effect
    &:hover .fst-row-item {
      // background-color: #f5fff5;
    }
  }

  .fst-foot {
    //
  } // .fst-foot
}
.action {
  display: none;
  font-size: 10px;
}
.fst-row:hover .action {
  display: flex;
}
</style>
