<template>
  <div class="block h-full ">
    <div class="voucher-card flex items-center justify-center overflow-hidden">
      <div class="promo-right-icon items-center justify-center">
        <span>
          <TimeAgo
            :title="expireDate"
            :datetime="expireDate"
            :auto-update="60"
          />
        </span>
      </div>
      <div
        class="vouchar-card-body items-left flex items-left px-8 flex-col w-full"
      >
        <div class="mb-8">
          <h3 class="text-left">{{ reward }}</h3>
          <p class="text-sm font-bold">
            {{ $t('components.voucherManagement.details.card.reward') }}
          </p>
        </div>
        <div class="mb-2">
          <p class="flex items-center justify-start mb-2">
            <i class="fa fa-check text-green-500 mr-3"></i>
            <span class="m-0"
              >{{ $t('components.voucherManagement.details.card.validTill') }}
              {{ expireAt }}</span
            >
          </p>
          <p class="flex items-center justify-start">
            <i class="fa fa-check text-green-500 mr-3"></i>
            <span class="m-0"
              >{{
                $t('components.voucherManagement.details.card.availableIn')
              }}
              {{ location }}</span
            >
          </p>
        </div>
      </div>
      <div class="v-bottom-right-icon overflow-hidden -bottom-7 -right-10">
        <div class="w-28 h-28 rounded-full v-circle"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VoucherDetailsCard',
  components: {},
  data() {
    return {
      isLoading: false,
      rd_color: 'rd_color',
      fd_color: 'fd_color',
      fr_color: 'fr_color',
      rd_bg: 'rd_bg',
      fd_bg: 'fd_bg',
      fr_bg: 'fr_bg',
    }
  },
  props: {
    type: {
      required: false,
    },
    reward: {
      required: false,
    },
    expireAt: {
      required: false,
    },
    expireDate: {
      required: false,
    },
    title: {
      required: false,
    },
    location: {
      required: false,
    },
  },
  mounted() {
    console.log('data')
  },
}
</script>

<style lang="scss" scoped>
.voucher-card {
  width: 100%;
  height: 100%;
  min-height: 200px;
  background: #e0f8e6;
  border-radius: 22px;
  position: relative;
  .promo-right-icon {
    width: 180px;
    height: 55px;
    background: #ee9200;
    position: absolute;
    right: -3px;
    top: -3px;
    border-top-right-radius: 22px;
    border-bottom-left-radius: 60px;
    display: flex;
    span {
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #ffffff;
      font-family: Roboto;
    }
  }
  .vouchar-card-body {
    h3 {
      font-family: Roboto;
      font-size: 37px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0d0d0d;
    }
    span {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
    }
  }
}
.rd_color {
  border-color: #ee9200 !important;
}
.fr_color {
  border-color: #a414f2 !important;
}
.fd_color {
  border-color: #0093ee !important;
}
.rd_bg {
  background: #ee9200 !important;
}
.fr_bg {
  background: #a414f2 !important;
}
.fd_bg {
  background: #0093ee !important;
}
.v-bottom-right-icon {
  position: absolute;
}
.v-circle {
  border: 37px solid #0dff99;
  background: none;
}
</style>
